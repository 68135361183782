import _ from 'lodash'
import React from 'react'
import { handle, Route } from './state'
import { MergeRoutes, RouteToURL } from './eigenstate';


export class Eigenstate extends React.Component {
    _update?: () => void
    componentDidMount() {
        this._update = () => this.setState({ })
        handle.attach(this._update)
    }
    componentWillUnmount() {
        if(this._update){
            handle.detach(this._update)
        }
    }
    render() {
        return React.cloneElement(this.props.children as any)
    }
}


export class Link extends React.Component<{
    className?: string
    href: string
    query?: typeof Route.query
    config?: typeof Route.config
    onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => any
}> {
    render() {
        let nextState = MergeRoutes(Route, this.props.href, {
            query: this.props.query,
            config: this.props.config,
        })
        let url = RouteToURL(nextState as any)
        return (
            <a
                {..._.omit(this.props, ['query', 'config', 'href', 'className', 'onClick'])}
                href={url}
                className={
                    (this.props.className || '') + (Route.path === nextState.path ? ' active' : '')
                }
                onClick={e => {
                    if (this.props.onClick) this.props.onClick(e)
                    if (
                        e.button === 0 &&
                        !e.metaKey &&
                        !e.shiftKey &&
                        !e.ctrlKey &&
                        !e.defaultPrevented
                    ) {
                        e.preventDefault()
                        Route.go(url)
                    }
                }}>
                {this.props.children}
            </a>
        )
    }
}