import _ from 'lodash'
import { RouteBuilder, StateBuilder, StoreBuilder, setup } from './eigenstate'


import { Entry, RuleTable, T, Not, Ref, And, Or } from './ontology';

const sample_data: Entry[] = [
    { name: 'Drew Houston', college: 'Massachussetts Institute of Technology', company: 'Dropbox' },
    { name: 'Adam Smith', college: 'MIT', company: 'Kite' },
    { name: 'Bob Metcalfe', college: 'MIT', company: '3com' },
    { name: 'Larry Page', college: 'Stanford', company: 'Google' },
    { name: 'Sergey Brin', college: 'Stanford', company: 'Google' },
    { name: 'Mark Zuckerberg', college: 'Harvard', company: 'Facebook' },
    { name: 'Bill Gates', college: 'Harvard', company: 'Microsoft' },
    { name: 'Paul Allen', college: 'Washington State University', company: 'Microsoft' },
    { name: 'Jacob Cole', college: 'Mass Tech + Oxford', company: 'IdeaFlow' },
    { name: 'Brian Acton', college: 'University of Central Florida', company: 'WhatsApp' },
    { name: 'Alan Eustace', college: 'University of Central Florida', company: 'Google' },
    { name: 'Steve Jobs', college: 'Reed College', company: 'Apple' },
    { name: 'Tim Cook', college: 'Auburn University', company: 'Apple' },
    { name: 'Steve Wozniak', college: 'UC Berkeley', company: 'Apple' },
    { name: 'Dylan Field', college: 'Brown', company: 'Figma' },
    { name: 'Evan Wallace', college: 'Brown', company: 'Figma' },
    { name: 'Walt Disney', college: 'None', company: 'Disney' },
    { name: 'Alexander Graham Bell', college: 'University College London', company: 'AT&T' },
    { name: 'Vannevar Bush', college: 'Tufts', company: 'Raytheon'},
    { name: 'Danny Hillis', college: 'MIT', company: 'Thinking Machines'},
    { name: 'Alexis Ohanian', college: 'UVA', company: 'Reddit'},
    { name: 'Steve Huffman', college: 'UVA', company: 'Reddit'},
    { name: 'Garry Tan', college: 'Stanford', company: 'Posterous'},
    { name: 'Richard Branson', college: 'N/A', company: 'Virgin Group'},
    { name: 'Arianna Huffington', college: 'Cambridge', company: 'Huffington Post'},
    { name: 'Rupert Murdoch', college: 'Oxford', company: 'News Corp'},
    { name: 'Steve Wozniak', college: 'UC Berkeley', company: 'Apple'},
    { name: 'Gordon Moore', college: 'UC Berkeley + Caltech', company: 'Intel'},
    { name: 'Robert Noyce', college: 'MIT', company: 'Intel'},
    { name: 'Pierre Omidyar', college: 'Tufts + UC Berkeley', company: 'eBay'},
    { name: 'Scott McNealy', college: 'Harvard + Stanford', company: 'Sun Microsystems'},
    { name: 'Andy Bechtolsheim', college: 'CMU + Stanford', company: 'Sun Microsystems'},
    { name: 'Bill Joy', college: 'University of Michigan + UC Berkeley', company: 'Sun Microsystems'},
    { name: 'Vinod Khosla', college: 'IIT Delhi + CMU + Stanford', company: 'Sun Microsystems'},
]



const sample_rules: RuleTable = {
    apple: T('company', 'Apple'),
    not_apple: Not(T('company', 'Apple')),
    not_elite: Not(Ref('elite_uni')),
    stanford_google: And(T('college', 'Stanford'), T('company', 'Google')),
    ivy_league: Or(T('college', 'Harvard'), T('college', 'Brown')),
    elite_uni: Or(Ref('ivy_league'), Or(T('college', 'MIT'), T('college', 'Stanford'))),
    public_school: Or(T('college', 'University of Central Florida'), T('college', 'UC Berkeley')),
    startup: Or(T('company', 'Figma'), T('company', 'WhatsApp')),
    elite_startup: And(Ref('elite_uni'), Ref('startup')),
    big_corp: Or(Or(Or(T('company', 'Google'), T('company', 'Apple')), T('company', 'Facebook')), T('company', 'Microsoft')),
    big_corp_public_school: And(Ref('big_corp'), Ref('public_school')),
    schools: Or(Ref('elite_uni'), Ref('public_school')),
    companies: Or(Ref('apple'), Or(Ref('startup'), Ref('public_company'))),
    public_company: Or(Ref('big_corp'), Or(T('company', 'Dropbox'), Or(T('company', 'AT&T'), T('company', 'Raytheon'))))
}

type RouteType = {
    query: {
        show?: 'matches' | 'rejects' | 'untagged',
        sort?: 'normal' | 'semantic'
    }
}

type StateType = {
    hover_item?: number
}

const defaultState: StateType = {
}

type StoreType = {
    data: Entry[]
    rules: RuleTable
    bundles: { [key: string]: {
        name: string
        // draft: boolean
    } }
}


const defaultStore: StoreType = {
    data: sample_data,
    rules: {},
    bundles: {}
}


export var State: StateBuilder<StateType>
export var Store: StoreBuilder<StoreType>
export var Route: RouteBuilder<RouteType>

export const handle = setup<StateType, StoreType, RouteType>({
    defaultState: defaultState,
    defaultStore: defaultStore,
    context: 'APP',
    timeout: 2000,
    dev: true
})

handle.attach(packet => {
    State = packet.state
    Store = packet.store
    Route = packet.route
})
