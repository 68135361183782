import _ from "lodash";
import React, {
    Component,
    ReactEventHandler,
    useState,
    useEffect,
    useRef,
    useCallback,
    ReactChildren,
    ReactChild,
    useContext
} from "react";
import "./App.css";

import { State, Store, Route } from "../lib/state";
import immer from "immer";
import { Link } from "../lib/eigenreact";
import {
    ontology_tree,
    OntologyTree,
    matching_rules,
    tokenize,
    Token,
    parse,
    Rule,
    And,
    Not,
    rule_check,
    Ref,
    Fuzzy,
    T,
    Or,
    Entry
} from "../lib/ontology";
import TooltipTrigger from "react-popper-tooltip";
import "react-popper-tooltip/dist/styles.css";

import neatCSV from "neat-csv";

function Header() {
    return (
        <header>
            <h1>foyer</h1>
            <span>the ontological data explorer</span>
            <div className="spacer" />

            <input
                className="file-selector-button"
                type="file"
                onChange={e => {
                    if (e.target.files && e.target.files.length > 0) {
                        handleFileSelect(e.target.files[0]);
                    }
                }}
            />
            <button
                onClick={e => {
                    Store.reset();
                }}
            >
                Reset
            </button>
        </header>
    );
}

function OntologyItem(props: { item: OntologyTree }) {
    let hovering_rules =
        State.hover_item !== undefined
            ? matching_rules(
                  Store.data[State.hover_item],
                  Store.rules,
                  Object.keys(Store.rules)
              )
            : {};

    let m = Route.match("/:rule");
    let [dragOver, setDragOver] = React.useState(false);

    return (
        <li
            onDrop={e => {
                e.preventDefault();
                e.stopPropagation();

                var draggedRuleID = e.dataTransfer.getData("rule");

                // when we drop a token onto another token
                // we add it as a rule to the thing that we dropped it onto

                if (draggedRuleID) {
                    let ruleId = props.item.id;

                    let newRule = Store.rules[ruleId]
                        ? Or(Store.rules[ruleId], Ref(draggedRuleID))
                        : Ref(draggedRuleID);

                    let errors = rule_check(
                        {
                            ...Store.rules,
                            [ruleId]: newRule
                        },
                        newRule
                    );

                    if (errors) {
                        alert(errors);
                    } else {
                        Store.update(
                            immer(data => {
                                data.rules[ruleId] = newRule;
                            })
                        );
                    }
                }

                setDragOver(false);
            }}
            className={dragOver ? "drag-over" : ""}
            onDragOver={e => {
                e.preventDefault();
                e.stopPropagation();
                setDragOver(true);
            }}
            onDragLeave={e => {
                e.preventDefault();
                setDragOver(false);
            }}
        >
            <TooltipTrigger
                placement="right"
                trigger="hover"
                tooltip={({
                    arrowRef,
                    tooltipRef,
                    getArrowProps,
                    getTooltipProps,
                    placement
                }) => (
                    <div
                        {...getTooltipProps({
                            ref: tooltipRef,
                            className: "tooltip-container"
                            /* your props here */
                        })}
                    >
                        <div
                            {...getArrowProps({
                                ref: arrowRef,
                                className: "tooltip-arrow",
                                "data-placement": placement
                                /* your props here */
                            })}
                        />
                        <div className="search rule-preview">
                            {tokenize(Store.rules[props.item.id]).map(
                                renderToken
                            )}
                        </div>
                    </div>
                )}
            >
                {({ getTriggerProps, triggerRef }) => (
                    <div
                        draggable
                        onDragStart={e => {
                            e.dataTransfer.setData("rule", props.item.id);
                        }}
                        {...getTriggerProps({
                            ref: triggerRef
                        })}
                    >
                        <Link
                            href={"/" + props.item.id}
                            className={
                                hovering_rules[props.item.id] ? "hovering" : ""
                            }
                        >
                            {hovering_rules[props.item.id] ||
                            (m && m.rule === props.item.id) ? (
                                <OntologyToken id={props.item.id} />
                            ) : (
                                <span className="ontology-inactive">
                                    {getName(props.item.id)}
                                </span>
                            )}
                        </Link>
                    </div>
                )}
            </TooltipTrigger>
            <ul>
                {props.item.children.map(entry => (
                    <OntologyItem key={entry.id} item={entry} />
                ))}
            </ul>
        </li>
    );
}

function Sidebar() {
    let list = ontology_tree(Store.rules);
    return (
        <div className="sidebar">
            <ul>
                <Link href={"/_all"}>
                    <span className="ontology-inactive special">All</span>
                </Link>
            </ul>
            <hr />
            <ul>
                {list.map(entry => (
                    <OntologyItem key={entry.id} item={entry} />
                ))}
                {list.length === 0 && (
                    <i style={{ color: "gray" }}>(no saved bundles)</i>
                )}
            </ul>
        </div>
    );
}

function google_colors(n: number) {
    var colors = [
        "#3366cc",
        "#dc3912",
        "#ff9900",
        "#109618",
        "#990099",
        "#0099c6",
        "#dd4477",
        "#66aa00",
        "#b82e2e",
        "#316395",
        "#994499",
        "#22aa99",
        "#aaaa11",
        "#6633cc",
        "#e67300",
        "#8b0707",
        "#651067",
        "#329262",
        "#5574a6",
        "#3b3eac"
    ];
    return colors[n % colors.length];
}

function OntologyToken(props: { id: string; mini?: boolean }) {
    let style = {
        background: google_colors(Object.keys(Store.rules).indexOf(props.id))
    };

    if (!Store.bundles[props.id]) {
        return <span>[Reference not found]</span>;
    }
    if (props.mini) {
        return (
            <span
                style={style}
                className="ontology-mini"
                title={getName(props.id)}
            ></span>
        );
    } else {
        return (
            <span style={style} className="ontology-token">
                {getName(props.id)}
            </span>
        );
    }
}


function getName(ruleId){
    if(Store.bundles[ruleId].name){
        return Store.bundles[ruleId].name    
    }else{
        return summarizeRule(Store.rules[ruleId])
    }
    
}

function Search({
    ruleId,
    rule,
    updateRule
}: {
    ruleId: string;
    rule?: Rule;
    updateRule: (rule: Rule) => void;
}) {
    let [text, setText] = useState("");
    let [tokens, setTokens] = useState(() => (rule ? tokenize(rule) : []));
    let [parseStatus, setParseStatus] = useState(null);
    let [dropdownIndex, setDropdownIndex] = useState(0);

    useEffect(() => {
        try {
            if (JSON.stringify(parse(tokens)) != JSON.stringify(rule)) {
                setTokens(rule ? tokenize(rule) : []);
            }
        } catch (err) {
            setTokens(rule ? tokenize(rule) : []);
        }

        setParseStatus(null);
        setText("");
        setDropdownIndex(0);
    }, [JSON.stringify(rule)]);

    function updateTokens(newTokens: Token[]) {
        setText("");
        setTokens(newTokens);
        setDropdownIndex(0);
        try {
            let newRule = parse(newTokens);

            let errors = rule_check(
                {
                    ...Store.rules,
                    [ruleId]: newRule
                },
                newRule
            );
            if (errors) throw new Error(errors);

            updateRule(newRule);
            setParseStatus(null);
        } catch (err) {
            setParseStatus(err);
        }
    }

    const inputEl = useRef(null);
    type DropdownOption = {
        text: string;
        token: Token;
    };

    let dropdownOptions: DropdownOption[] = [];

    let expectsFilter = false;
    if (text.length > 0) {
        let lastToken = tokens[tokens.length - 1];
        
        expectsFilter = (tokens.length === 0 ||
                    lastToken == "AND" ||
                    lastToken == "OR" ||
                    lastToken === "NOT" ||
                    lastToken === "(");
        if (expectsFilter) {
            for (let id in Store.rules) {
                if(id !== ruleId){
                    dropdownOptions.push({
                        text: getName(id),
                        token: Ref(id)
                    });
                }
            }

            let KVs: any = {};
            for (let row of Store.data) {
                for (let key in row) {
                    let k = key + "---" + (row as any)[key];
                    if (k in KVs) {
                        KVs[k]++;
                    } else {
                        KVs[k] = 1;
                    }
                }
            }

            for (let k in KVs) {
                let [key, val] = k.split("---"),
                    count = KVs[k];

                if (count > 1) {
                    dropdownOptions.push({
                        text: k,
                        token: T(key, val)
                    });
                }
            }


            if (lastToken !== "NOT") {
                dropdownOptions.push({ text: "not", token: "NOT" });
            }
        } else if (typeof lastToken != "string") {
            dropdownOptions.push({ text: "or", token: "OR" });
            dropdownOptions.push({ text: "and", token: "AND" });
        }
    }

    let dropdownResults = dropdownOptions
        .filter(option =>
            option.text.toLowerCase().includes(text.toLowerCase())
        )
        .map(k => k.token);

    if(!dropdownResults.some(k => typeof k === 'string') && expectsFilter){
        dropdownResults.push(Fuzzy(text));
    }

    return (
        <div
            className={"search " + (parseStatus ? "error" : "")}
            onClick={e => {
                if (inputEl.current)
                    ((inputEl.current as any) as HTMLInputElement).focus();
            }}
        >
            {tokens.map((tok, i) => <span key={i} className="clickable-token" onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                // console.log(e)

                let copy = tokens.slice(0)
                
                let isOperator = x => x === 'AND' || x === 'OR'
                if(!isOperator(tok) && i === 0 && isOperator(copy[1])){
                    copy.splice(0, 2) // remove token + operator
                }else if(!isOperator(tok) && i !== 0 && isOperator(copy[i - 1])){
                    copy.splice(i - 1, 2) // remove operator + token
                }else{
                    copy.splice(i, 1); // remove only the token clicked on
                }

                updateTokens(copy)
                
            }}>{renderToken(tok, i)}</span>)}
            <div className="input">
                <input
                    type="text"
                    value={text}
                    ref={inputEl}
                    onChange={e => setText(e.target.value)}
                    onKeyDown={e => {
                        let input = e.target as HTMLInputElement;
                        // we're at the end, and they pressed a space
                        if (
                            input.selectionStart === input.value.length &&
                            e.keyCode === 32
                        ) {
                            if (input.value.toLowerCase() === "or") {
                                e.preventDefault();
                                updateTokens([...tokens, "OR"]);
                            } else if (input.value.toLowerCase() === "and") {
                                e.preventDefault();
                                updateTokens([...tokens, "AND"]);
                            } else if (input.value.toLowerCase() === "not") {
                                e.preventDefault();
                                updateTokens([...tokens, "NOT"]);
                            }
                        }
                        if (input.value === "") {
                            if (e.keyCode == 57) {
                                e.preventDefault();
                                updateTokens([...tokens, "("]);
                            } else if (e.keyCode === 48) {
                                e.preventDefault();
                                updateTokens([...tokens, ")"]);
                            }
                        }

                        // if we hit backspace at the end of the thing
                        if (
                            input.value.length == 0 &&
                            e.keyCode === 8 &&
                            tokens.length > 0
                        ) {
                            if (
                                typeof tokens[tokens.length - 1] != "string" &&
                                ["AND", "OR"].includes(tokens[
                                    tokens.length - 2
                                ] as string)
                            ) {
                                updateTokens(tokens.slice(0, -2));
                            } else {
                                updateTokens(tokens.slice(0, -1));
                            }
                        }
                        if (e.keyCode == 40) {
                            // down
                            e.preventDefault();
                            setDropdownIndex(
                                Math.max(
                                    0,
                                    Math.min(
                                        dropdownResults.length - 1,
                                        dropdownIndex + 1
                                    )
                                )
                            );
                        }
                        if (e.keyCode == 38) {
                            // up
                            e.preventDefault();
                            setDropdownIndex(
                                Math.max(
                                    0,
                                    Math.min(
                                        dropdownResults.length - 1,
                                        dropdownIndex - 1
                                    )
                                )
                            );
                        }
                        if (e.keyCode === 9 || e.keyCode === 13) {
                            // tab or enter
                            e.preventDefault();
                            if (dropdownIndex < dropdownResults.length) {
                                updateTokens([
                                    ...tokens,
                                    dropdownResults[dropdownIndex]
                                ]);
                            }
                        }
                    }}
                ></input>
                {dropdownResults.length > 0 && (
                    <div className="dropdown">
                        {dropdownResults.map((item, i) => {
                            let el = renderToken(item, i);
                            return (
                                <div
                                    key={i}
                                    className={
                                        i === dropdownIndex
                                            ? "result selected"
                                            : "result"
                                    }
                                >
                                    {el}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}

function renderToken(tok: Token, i: number) {
    if (typeof tok === "string") {
        return (
            <span key={i} className="operator">
                {" "}
                {tok.toLowerCase()}{" "}
            </span>
        );
    } else if (tok.type === "ref") {
        // return <span key={i}>{JSON.stringify(tok)}</span>
        return <OntologyToken key={i} id={tok.id} />;
    } else if (tok.type === "tok") {
        return (
            <span key={i} className="token">
                <i>{tok.key}:</i> {tok.value}
            </span>
        );
    } else if (tok.type === "fuzzy") {
        return (
            <span key={i} className="token">
                <i>...</i>
                {tok.query}
                <i>...</i>
            </span>
        );
    } else {
        return <span>(unable to render)</span>;
    }
}

function summarizeRule(rule: Rule) {
    return tokenize(rule)
        .filter(k => typeof k != "string")
        .map((k: any) => {
            if (k.type == "ref") return getName(k.id);
            if (k.type == "tok") return k.value;
            if (k.type == "fuzzy") return k.query;
            return "";
        })
        .join("/");
}

function createRule() {
    let id = createID();
    Route.update({
        path: "/" + id,
        push: true
    });
    return id;
}

function createID() {
    return Math.random()
        .toString(36)
        .slice(2, 8);
}

function Main() {
    let all_rules = Object.keys(Store.rules);
    let results = Store.data.map((row, i) => {
        return {
            matches: matching_rules(row, Store.rules, all_rules),
            data: row,
            index: i
        };
    });

    let m = Route.match("/:rule");
    let exists = m && Store.bundles[m.rule];

    let show;
    if (Route.query.show === "rejects") {
        show = "rejects";
    } else if (Route.query.show === "untagged") {
        show = "untagged";
    } else {
        show = "matches";
    }

    function updateRule(newRule: Rule) {
        let errors = rule_check(
            {
                ...Store.rules,
                [exists ? m!.rule : '__temp__']: newRule
            },
            newRule
        );
        if(errors) return alert(errors);

        if (exists) {
            let id = m!.rule;
            Store.update(
                immer(data => {
                    data.rules[id] = newRule;
                })
            );
        } else {
            let id = createRule();
            Store.update(
                immer(data => {
                    data.rules[id] = newRule;
                    data.bundles[id] = {
                        name: ""
                    };
                })
            );
        }
    }

    let currentRule = exists ? Store.rules[m!.rule] : undefined;
    let matches = results.filter(k => (exists ? k.matches[m!.rule] : true));

    let view_results = results.filter(k => {
        if (show === "untagged") return Object.values(k.matches).every(k => !k);
        if (show === "matches") return exists ? k.matches[m!.rule] : true;
        if (show === "rejects") return exists ? !k.matches[m!.rule] : false;
    });

    // let semantic_sort = Route.query.sort !== "normal"; // semantic default
    let semantic_sort = Route.query.sort === "semantic"; // normal default

    if (
        (show === "rejects" || show === "untagged") &&
        exists &&
        semantic_sort
    ) {
        // If we are in the untagged or rejected view we should sort
        // the results by their probability of being a false negative
        // so that the user can most easily discover and add missing rules

        let words = {};
        let freq = {};

        results.forEach(k => {
            for (let word of Object.values(k.data)
                .join(" ")
                .split(/\s/)) {
                if (!words[word]) words[word] = 0;
                if (!freq[word]) freq[word] = 0;
                // words[word] += k.matches[m!.rule] ? 1 : -1
                words[word] += k.matches[m!.rule] ? 1 : 0;
                freq[word]++;
            }
        });

        view_results = _.sortBy(view_results, k => {
            let toks = Object.values(k.data)
                .join(" ")
                .split(/\s/);
            return (
                -_.sum(toks.map(w => (words[w] || 0) / (freq[w] || 1))) /
                toks.length
            );
        });
    }


    function combineRule(newRule){

        if (currentRule) {
            if (
                exists &&
                show === "matches"
            ) {
                updateRule(
                    And(
                        currentRule,
                        newRule
                    )
                );
            } else {
                updateRule(
                    Or(
                        currentRule,
                        newRule
                    )
                );
            }
        } else {
            updateRule(newRule);
        }
    }

    return (
        <div className="main">
            <div className="controls">
                <h2
                    style={{
                        display: "flex"
                    }}
                >
                    <input
                        value={exists ? Store.bundles[m!.rule].name : ""}
                        placeholder={exists ? summarizeRule(Store.rules[m!.rule]) : "All"}
                        onChange={e => {
                            if (exists) {
                                let id = m!.rule;

                                Store.update(
                                    immer(data => {
                                        data.bundles[id].name = e.target.value;
                                    })
                                );
                            } else {
                                let id = createRule();
                                Store.update(
                                    immer(data => {
                                        data.rules[id] = null;
                                        data.bundles[id] = {
                                            name: e.target.value
                                        };
                                    })
                                );
                            }
                        }}
                    />
                    {exists && (
                        <button
                            onClick={e => {
                                let id = m!.rule;
                                let newId = createID();
                                Store.update(
                                    immer(data => {
                                        data.rules[newId] = _.cloneDeep(
                                            data.rules[id]
                                        );
                                        data.bundles[newId] = {
                                            ...data.bundles[id],
                                            name:
                                                "Copy of " + getName(id)
                                        };
                                    })
                                );
                                Route.update({
                                    path: "/" + newId,
                                    push: true
                                });
                            }}
                        >
                            Fork
                        </button>
                    )}{" "}
                    {exists && (
                        <button
                            onClick={e => {
                                if (
                                    confirm(
                                        "Are you sure you want to delete this category?"
                                    )
                                ) {
                                    let id = m!.rule;
                                    Route.go("/_all");
                                    Store.update(
                                        immer(data => {
                                            delete data.bundles[id];
                                            delete data.rules[id];
                                        })
                                    );
                                }
                            }}
                        >
                            &times;
                        </button>
                    )}
                </h2>

                <Search
                    ruleId={exists ? m!.rule : ""}
                    rule={currentRule}
                    updateRule={newRule => {
                        updateRule(newRule);
                    }}
                />

                <div id="tabs">
                    <Link
                        href="?show=matches"
                        className={
                            (show === "matches" ? "selected" : "") +
                            " tab matches"
                        }
                    >
                        Matches (
                        {
                            results.filter(k => !exists || k.matches[m!.rule])
                                .length
                        }
                        )
                    </Link>{" "}
                    <Link
                        href="?show=rejects"
                        className={
                            (show === "rejects" ? "selected" : "") +
                            " tab rejects"
                        }
                    >
                        Rejects (
                        {
                            results.filter(k => exists && !k.matches[m!.rule])
                                .length
                        }
                        )
                    </Link>
                    <Link
                        href="?show=untagged"
                        className={
                            (show === "untagged" ? "selected" : "") +
                            " tab untagged"
                        }
                    >
                        Untagged (
                        {
                            results.filter(k =>
                                Object.values(k.matches).every(k => !k)
                            ).length
                        }
                        )
                    </Link>
                    <div style={{ flexGrow: 1 }} />
                    {show !== "matches" && (
                        <label>
                            <input
                                type="checkbox"
                                checked={semantic_sort}
                                onChange={e => {
                                    Route.update({
                                        query: {
                                            ...Route.query,
                                            sort: e.target.checked
                                                ? "semantic"
                                                : "normal"
                                        }
                                    });
                                }}
                            />{" "}
                            Semantic Sorting
                        </label>
                    )}
                </div>
            </div>
            <div
                className={"results " + show}
                onMouseLeave={e => {
                    State.update({ hover_item: undefined });
                }}
            >
                <table>
                    <thead>
                        <tr>
                            <th>groups</th>
                            {Object.keys(Store.data[0]).map(key => (
                                <th key={key}>{key}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {view_results.map(k => (
                            <tr
                                key={k.index}
                                className={
                                    "result " +
                                    (State.hover_item === k.index
                                        ? " hover"
                                        : "")
                                }
                                onMouseOver={e => {
                                    State.update({ hover_item: k.index });
                                }}
                            >
                                <td style={{ width: 100 }}>
                                    {Object.keys(k.matches)
                                        .filter(j => k.matches[j])
                                        .map(k => (
                                            <span key={k} className="clickable-token" onClick={e => {
                                                combineRule(e.shiftKey ? Not(Ref(k)) : Ref(k))
                                            }}><OntologyToken
                                                mini
                                                id={k}
                                            /></span>
                                        ))}
                                </td>
                                {Object.entries(k.data).map(([key, val]) => (
                                    <td key={key}>
                                        <span
                                            className="token"
                                            title={
                                                (exists &&
                                                    show === "matches") ||
                                                !currentRule
                                                    ? `Restrict query to results where ${key}: ${val}`
                                                    : `Include results where ${key}: ${val}`
                                            }
                                            onClick={e => {
                                                combineRule(e.shiftKey ? Not(T(key, val)) : T(key, val))
                                            }}
                                        >
                                            {val}
                                        </span>
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

function DragonDroppable({
    children,
    onFiles
}: {
    children: ReactChild;
    onFiles: (files: FileList) => void;
}) {
    return (
        <div
            onDragEnter={e => {
                e.stopPropagation();
                e.preventDefault();
            }}
            onDrop={e => {
                e.stopPropagation();
                e.preventDefault();
                if (e.dataTransfer.files.length > 0) {
                    onFiles(e.dataTransfer.files);
                }
            }}
            onDragOver={e => {
                e.stopPropagation();
                e.preventDefault();
            }}
        >
            {children}
        </div>
    );
}

function readFileAsText(file: File): Promise<string> {
    let fr = new FileReader();
    return new Promise((resolve, reject) => {
        fr.onload = () => {
            resolve(fr.result as string);
        };
        fr.onerror = err => {
            reject(err);
        };
        fr.readAsText(file);
    });
}

async function handleFileSelect(file: File) {
    let text = await readFileAsText(file);

    let json;
    let data;

    try {
        json = JSON.parse(text);
    } catch (err) {}

    if (json && json.nodes) {
        data = json.nodes
            .map((k: any) => ({
                title: k.title || "",
                description: k.description || ""
            }))
            .filter(k => k.title || k.description);
    } else if (Array.isArray(json)) {
        data = json
            .map((k: any) => ({
                text: typeof k === "string" ? k : k.text
            }))
            .filter(k => k.text);
    } else if (file.type === "text/tab-separated-values") {
        let csv = await neatCSV(text, {
            separator: "\t"
        });
        data = csv.map(data => {
            return (_.mapValues(data, value => {
                return (value || "").toString().replace(/\r\n?/g, "\n");
            }) as any) as Entry;
        });
    } else if (file.type === "text/csv") {
        let csv = await neatCSV(text);
        data = csv.map(data => {
            return (_.mapValues(data, value => {
                return (value || "").toString().replace(/\r\n?/g, "\n");
            }) as any) as Entry;
        });
    }

    if (data) {
        Store.update(
            immer(store => {
                store.data = data;
                store.rules = {};
                store.bundles = {};
            })
        );
    } else {
        alert("Unable to import file");
    }
}

function App() {
    return (
        <DragonDroppable
            onFiles={files => {
                handleFileSelect(files[0]);
            }}
        >
            <div className="app">
                <Header />
                <div className="container">
                    <Sidebar />
                    <Main />
                </div>
            </div>
        </DragonDroppable>
    );
}

export default App;
