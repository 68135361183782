import _ from 'lodash'
import queryString from 'query-string'
import deepFreeze from 'deep-freeze'
import pathToRegexp, { Key } from 'path-to-regexp'
import { resolve as resolveURL, parse as parseURL } from 'url'


type Eigen<T> = T & {
    // update(updater: ((old: T) => T) | T): void
    update(updater: (old: T) => T): void
    update(data: Partial<T>): void

    reset(): void
}

type RouteOptions = {
    desc?: string
    push?: boolean
    replace?: boolean
    
    path?: string
    query?: {}
    config?: {}
}

export type StateBuilder<T> = Eigen<T>
export type StoreBuilder<T> = Eigen<T>
export type RouteBuilder<T> = T & {
    // update: (updater: (old: T) => T & RouteOptions) => void
    update(updater: (old: T) => T & RouteOptions): void
    update(data: Partial<T & RouteOptions>): void

    go(href: string, obj?: Partial<T & RouteOptions>): void
    go(obj: Partial<T & RouteOptions>): void

    match(pattern: string, options?: MatchOptions): null | { [key: string]: string }

    path: string
    query: {}
    config: {}
}

type UpdatePacket<StateType, StoreType, RouteType> = {
    state: StateBuilder<StateType>
    store: StoreBuilder<StoreType>
    route: RouteBuilder<RouteType>
}


type Options<StateType, StoreType, RouteType> = {
    defaultState: StateType
    defaultStore: StoreType
    context: string
    timeout: number
    dev: boolean
}

export type EigenstateHandle<StateType, StoreType, RouteType> = {
    dispose: () => void
    attach: (listener: (packet: UpdatePacket<StateType, StoreType, RouteType>) => void) => void,
    detach: (listener: (packet: UpdatePacket<StateType, StoreType, RouteType>) => void) => void,
}

export function setup<StateType, StoreType, RouteType>(
    options: Options<StateType, StoreType, RouteType>,
): EigenstateHandle<StateType, StoreType, RouteType> {

    let state: StateType = options.defaultState;
    
    function nextState<A, B>(updater: ((old: A) => B) | Partial<B>, currentState: A): B {
        if(typeof updater === 'function'){
            return (updater as ((old: A) => B))(currentState)
        }else{
            return { ...currentState, ...updater } as any
        }
    }

    function optionalFreeze<T>(data: T): T {
        return data;
        // return deepFreeze(data as any) as T
    }

    function updateState(updater: ((old: StateType) => StateType) | Partial<StateType> ): void {
        state = nextState(updater, state)
        update()
    }

    function getState(): StateBuilder<StateType>{
        return optionalFreeze({
            ...state as StateType,
            update: updateState,
            reset: () => updateState(e => options.defaultState)
        })
    }

    let lastURLUpdate = 0;
    function updateRoute(updater: ((old: RouteType & BaseRoute) => RouteType & RouteOptions) | (RouteType & RouteOptions)){
        let result = nextState(updater, currentRoute)
        let url = RouteToURL(result as any)
        if (
            (Date.now() - lastURLUpdate > options.timeout || result.push) && !result.replace &&
            url !== location.pathname + location.search + location.hash // never pushstate for same url
        ) {
            history.pushState(result, result.desc || '', url)
        } else {
            history.replaceState(result, result.desc || '', url)
        }
        lastURLUpdate = Date.now()
        update()
    }

    let currentRoute: any;

    
    function go(href: string, obj?: Partial<RouteType & RouteOptions>): void
    function go(obj: Partial<RouteType & RouteOptions>): void
    function go(arg1: any, arg2?: any) {
        let href = '', obj: RouteType & RouteOptions = {} as any;
        if(typeof arg1 === 'string'){
            href = arg1
            obj = arg2
        }else{
            obj = arg1
        }
        return updateRoute(currentRoute => MergeRoutes<RouteType>(currentRoute, href, obj))
    }


    function getRoute(): RouteBuilder<RouteType>{
        currentRoute = URLtoRoute()
        return optionalFreeze({
            ...currentRoute,
            update: updateRoute,
            go: go,
            match(path: string, options?: MatchOptions){
                return match(currentRoute.path, path, options)
            }
        } as RouteBuilder<RouteType>)
    }
    
    function updateStore(updater: ((old: StoreType) => StoreType) | Partial<StoreType>){
        localStorage[options.context] = JSON.stringify(nextState(updater, _getStore()))
        update()
    }

    function _getStore(): StoreType {
        let appStore: StoreType | undefined;
        try {
            appStore = JSON.parse(localStorage[options.context])
        } catch (err) { }
        return appStore || options.defaultStore
    }

    function getStore(): StoreBuilder<StoreType>{
        return optionalFreeze({
            ..._getStore(),
            update: updateStore,
            reset: () => updateStore(e => options.defaultStore)
        })
    }

    let listeners: ((packet: UpdatePacket<StateType, StoreType, RouteType>) => void)[] = []
    
    let latestPacket: UpdatePacket<StateType, StoreType, RouteType>;

    function update(){
        latestPacket = {
            state: getState(),
            route: getRoute(),
            store: getStore()
        }
        for(let fn of listeners){
            try {
                fn(latestPacket)
            } catch (err) {
                console.warn(`Error encountered while triggering listener`, err)
            }
        }
    }

    if(options.dev && Object.defineProperty){
        exposeGlobal('Store', getStore)
        exposeGlobal('State', getState)
        exposeGlobal('Route', getRoute)
    }

    // we want things to trigger across pages as well
    function storageListener(e: StorageEvent){
        if (e.key === options.context) update()
    }

    function popstateListener(e: PopStateEvent){
        update()
    }
    
    window.addEventListener('storage', storageListener)
    window.addEventListener('popstate', popstateListener)

    update()

    return {
        dispose(){
            window.removeEventListener('storage', storageListener)
            window.removeEventListener('popstate', popstateListener)
        },
        attach(listener: (packet: UpdatePacket<StateType, StoreType, RouteType>) => void){
            listeners.push(listener)
            listener(latestPacket)
        },
        detach(listener: (packet: UpdatePacket<StateType, StoreType, RouteType>) => void){
            listeners = listeners.filter(k => k !== listener)
        }
    }
}

const isObjectEmpty = (x?: object) => Object.getOwnPropertyNames(x || {}).length === 0


type BaseRoute = {
    query: {}
    config: {}
    path: string
}

export function RouteToURL(obj: BaseRoute) {
    let path = obj.path || '/',
        search = isObjectEmpty(obj.query) ? '' : '?' + queryString.stringify(obj.query),
        hash = isObjectEmpty(obj.config) ? '' : '#' + queryString.stringify(obj.config)
    return path + search + hash
}

function URLtoRoute(url?: string): BaseRoute {
    let loc: any = location
    if (url) {
        loc = parseURL(resolveURL(location.href, url))
    }
    return {
        path: loc.pathname || '/',
        // For some reason queryString.parse returns an object
        // without the object prototype.

        // https://github.com/hapijs/hapi/issues/3280
        // https://github.com/nodejs/node/pull/6289/files
        query: { ...queryString.parse(loc.search || '') },
        config: { ...queryString.parse(loc.hash || '') },
    }
}

export function MergeRoutes<T>(currentRoute: T & BaseRoute, href: string, obj: T & RouteOptions): T & RouteOptions {
    if(!obj) obj = {} as any;

    let view = URLtoRoute(href)
    let base = {
        path: view.path,
        config: { ...currentRoute.config, ...view.config, ...(obj as any).config },
        push: obj.push,
        replace: obj.replace,
        desc: obj.desc
    }
    if(currentRoute.path !== view.path){
        // reset query, update config, reset path
        return {
            ...base,
            query: { ...view.query, ...(obj as any).query },
        } as any
    } else {
        // update query, update config, reset path
        return {
            ...base,
            query: { ...currentRoute.query, ...view.query, ...(obj as any).query },
        } as any
    }
}

function ensureWebConsole() {
    if((new Error().stack || '').indexOf('<anonymous>:') == -1){
        throw new Error(
            'State and Route globals can only be used from web console.' +
            'You probably forgot to import { State, Route } from "sr1" from some file.')
    }
}

function exposeGlobal(name: string, getter: () => any) {
    if(Object.defineProperty) Object.defineProperty(global, name, {
        get: function() {
            ensureWebConsole()
            return getter()
        },
        configurable: true,
    })
}

type MatchOptions = {
    exact?: boolean,
    strict?: boolean,
    sensitive?: boolean
}

function match(pathname: string, pattern: string, options?: MatchOptions) : { [key: string]: string } | null {
    const { exact = true, strict = false, sensitive = false } = options || {}
    const keys: Key[] = []
    const re = pathToRegexp(pattern, keys, { end: exact, strict, sensitive })
    const match = re.exec(pathname)
    if (!match) return null
    const [url, ...values] = match
    return keys.reduce((memo, key, index) => {
        memo[key.name] = values[index]
        return memo
    }, {} as { [key: string]: string })
}
