export interface Entry {
    // name: string
    // college: string
    // company: string
    [key: string]: string
}

export type RefRule = {
    type: 'ref'
    id: string
}

export type TokRule = {
    type: 'tok'
    key: string
    value: string
}


export type FuzzyRule = {
    type: 'fuzzy'
    query: string
}


type OperatorRule = {
    type: 'AND' | 'OR' | 'NOT'
    args: Rule[]
}

export type TerminalRule = TokRule | FuzzyRule | RefRule 

export type Rule = OperatorRule | TerminalRule

export type Token = TerminalRule | 'NOT' | 'AND' | 'OR' | '(' | ')'


export function Ref(id: string): RefRule {
    return { type: 'ref', id: id }
}

export function T(key: string, value: string): TokRule {
    return { type: 'tok', key: key, value: value }
}

export function Fuzzy(query: string): FuzzyRule {
    return { type: 'fuzzy', query: query }
}

export function And(lhs: Rule, rhs: Rule): OperatorRule {
    return { type: 'AND', args: [lhs, rhs] }
}

export function Or(lhs: Rule, rhs: Rule): OperatorRule {
    return { type: 'OR', args: [lhs, rhs] }
}

export function Not(arg: Rule): OperatorRule {
    return { type: 'NOT', args: [arg] }
}

export type RuleTable = {
    [key: string]: Rule
}


function invar(cond: boolean, message: string) {
    if (!cond) throw new Error(message);
}





export function tokenize(rule: Rule): Token[] {
    let tokens: Token[] = []

    function helper(rule: Rule, parent: Rule | null){
        if(!rule) return;

        // these are the terminal nodes which are always added to the token stream
        if(rule.type == 'tok' || rule.type == 'ref' || rule.type === 'fuzzy'){
            tokens.push(rule)
            return
        }

        // we're probably dealing with some means of combination, so we need
        // to consider whether or not we wrap this in parentheses
        let shouldParenthesize = parent && (parent.type != rule.type)
        if(shouldParenthesize) tokens.push('(');

        if(rule.type == 'NOT'){
            tokens.push('NOT')
            helper(rule.args[0], rule)
        }else if(rule.type == 'AND' || rule.type == 'OR'){
            helper(rule.args[0], rule)
            tokens.push(rule.type)
            helper(rule.args[1], rule)
        }else{
            throw new Error(`Unexpected node type "${rule.type}"`)
        }

        if(shouldParenthesize) tokens.push(')')
    }

    helper(rule, null)
    return tokens
}

// parsing is the inverse of tokenization here
export function parse(tokens: Token[]): Rule {
    function parsePrimary(): Rule {
        if(typeof tokens[0] != 'string'){
            // if its a non-operator token just return it and advance
            return tokens.shift() as Rule
        }else if(tokens[0] === '('){
            tokens.shift()
            // parse expression here until next parentheses
            let expr = parseExpression()
            if(tokens[0] === ')'){
                tokens.shift()
            }else{
                console.warn(`Expression ended before finding close paren`)
            }
            return expr
        }else if(tokens[0] === 'NOT'){
            tokens.shift()
            return Not(parsePrimary())
        }else{
            throw new Error(`Unexpected token ${JSON.stringify(tokens[0])}`)
        }
    }
    
    function parseExpression(): Rule {
        let lhs = parsePrimary()
        while(true){
            if(tokens.length === 0 || tokens[0] === ')'){
                return lhs
            }else if(tokens[0] === 'AND'){
                tokens.shift()
                lhs = And(lhs, parsePrimary())
            }else if(tokens[0] === 'OR'){
                tokens.shift()
                lhs = Or(lhs, parsePrimary())
            }else{
                throw new Error(`Unexpected token ${JSON.stringify(tokens[0])}`)
            }
        }
    }

    // clone tokens so we dont mess around with other peoples objects
    tokens = tokens.slice(0)
    if(tokens.length === 0){
        return null;
        // throw new Error(`Token list can not be empty`)
    }
    let expr = parseExpression();
    if(tokens.length > 0){
        console.warn(`Parsing did not consume all tokens (likely due to too many close parens)`)
    }
    return expr;
}


// This will give us hierarchical disjunctive decomposition of rules whereby
// the first elements represent the broadest categories.

export type OntologyTree = {
    id: string
    children: OntologyTree[]
    size: number
}

export function ontology_tree(rules: RuleTable) {
    // each rule has a set of rules that it is smaller than
    // for each rule we want to determine the longest possible path
    // we do this by assigning a path to each rule that maximizes the
    // possible length of that path

    let partialOrderingMap: {[key: string]: string[]} = {}
    for(let id in rules){
        partialOrderingMap[id] = []
    }

    function findPartialOrderings(id: string, rule: Rule, parent: Rule | null){
        if(!rule) return;
        if(rule.type === 'AND' || rule.type === 'OR'){
            if(parent && parent.type != rule.type) return;
            for(let arg of rule.args) findPartialOrderings(id, arg, rule);
        }else if(rule.type === 'ref' && parent){
            if(parent.type === 'AND' && partialOrderingMap[id]){
                // rule.id > id
                partialOrderingMap[id].push(rule.id)
            }else if(parent.type === 'OR' && partialOrderingMap[rule.id]){
                // rule.id < id
                partialOrderingMap[rule.id].push(id)
            }
        }else if(rule.type === 'ref'){
            partialOrderingMap[id].push(rule.id)
        }
    }

    for(let id in rules){
        findPartialOrderings(id, rules[id], null)
    }

    let longestPathMap: {[key: string]: string[]} = {}

    function findLongestPath(id: string): string[] {
        if(id in longestPathMap) return longestPathMap[id];

        let largerThings = partialOrderingMap[id]
        let longestPath: string[] = []
        for(let thing of largerThings){
            let path = [...findLongestPath(thing), thing]
            if(path.length > longestPath.length) longestPath = path;
        }
        longestPathMap[id] = longestPath;
        return longestPath
    }
    
    for(let id in rules){
        findLongestPath(id)
    }

    
    function generateTree(parent: string): OntologyTree {
        let obj: OntologyTree = {
            id: parent,
            children: [],
            size: 1
        }
        for(let id in rules){
            let path = longestPathMap[id]
            if(path[path.length - 1] == parent){
                let sub = generateTree(id)
                obj.children.push(sub)
                obj.size += sub.size
            }
        }
        obj.children.sort((b, a) => a.size - b.size)
        return obj
    }

    let results: OntologyTree[] = []
    for(let id in rules){
        if(longestPathMap[id].length == 0){
            results.push(generateTree(id))
        }
    }
    results.sort((b, a) => a.size - b.size)

    return results
}


export function rule_check(rules: RuleTable, rule: Rule): null | string {
    function helper(rule: Rule, stack: Rule[]) {
        invar(!stack.includes(rule), `Rule must not include recursive reference`)
        let s = [...stack, rule]
        if(rule === null){
            return;
        }else if(['AND', 'OR'].includes(rule.type)){
            let r = rule as OperatorRule
            invar(r.args.length === 2, `AND and OR must have exactly 2 arguments`)
            for(let arg of r.args) helper(arg, s);
        }else if(['NOT'].includes(rule.type)){
            let r = rule as OperatorRule
            invar(r.args.length === 1, `NOT must have exactly 1 argument`)
            for(let arg of r.args) helper(arg, s);
        }else if(rule.type === 'ref'){
            let r = rule as RefRule;
            invar(r.id in rules, `Unable to find referenced rule "${r.id}"`)
            helper(rules[r.id], s)
        }else if(rule.type === 'tok' || rule.type === 'fuzzy'){
        }else{
            throw new Error(`Unrecognized node type "${rule.type}"`)
        }
    }
    // a rule must not include a reference to itself, nor anything that refers to itself
    // returns null if the rule is valid, returns a string if there are any problems
    try {
        helper(rule, [])
        return null
    } catch (err) {
        return err.message
    }
}


// This function takes a data entry, dictionary of rules, and a list of rules to check
// it is careful not to duplicate effort re-evaluating common dependencies

export function matching_rules(row: Entry, rules: RuleTable, keys: string[]): { [key: string]: boolean } {
    let rule_results: { [key: string]: boolean } = {}

    function helper_ref(key: string): boolean {
        if(key in rule_results){
            return rule_results[key]
        }else{
            rule_results[key] = helper(rules[key])
            return rule_results[key]
        }
    }

    function helper(rule: Rule): boolean {
        if(!rule){
            return true;
        }else if(rule.type === 'OR'){
            return helper(rule.args[0]) || helper(rule.args[1])
        }else if(rule.type === 'AND'){
            return helper(rule.args[0]) && helper(rule.args[1])
        }else if(rule.type === 'NOT'){
            return !helper(rule.args[0])
        }else if(rule.type === 'ref'){
            invar(rule.id in rules, `Missing rule reference "${rule.id}"`)
            return helper_ref(rule.id)
        }else if(rule.type === 'tok'){
            return rule.value === row[rule.key as keyof Entry]
        }else if(rule.type === 'fuzzy'){
            let q = (rule as FuzzyRule).query.toLowerCase()
            return Object.values(row)
                .some(k => 
                    k.toString().toLowerCase().includes(q))
        }else{
            throw new Error(`Unrecognized node type "${rule.type}"`)
        }
    }

    

    
    for (let id of keys) {
        try {
            helper_ref(id)
        } catch (err) {
            console.error(err)    
        }
    }

    return rule_results
}

export function matches_rule(row: Entry, rules: RuleTable, key: string): boolean {
    return matching_rules(row, rules, [ key ])[ key ]
}


